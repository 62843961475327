
import React, { useState, useEffect } from 'react';
import { ActionApiService } from '../services/action.api.service';
import Lottie from 'react-lottie';
import { toast, Bounce } from 'react-toastify';
import loadingAD from '../lotties/loading.json';
import bigfireAD from '../lotties/big_fire.json';
import { TonCenterApiService } from '../services/toncenter.api.service';
import TonWeb from "tonweb";
import { TonHelper } from '../helpers/ton-helper';
import FormattedNumberInput from './formattedNumberInput';
import { formattedNumber } from './formattedNumber';
import fireAD from '../lotties/fire.json';
import { ProfileApiService } from '../services/profile.api.service';
import { StaticDataHelper } from '../helpers/static-data-helper';
import { TonConnectBurnCrisper } from './ton-connect-burn-crisper';
import { TonConnectCrisper } from './ton-connect-crisper';
import { InfoApiService } from '../services/info.api.service';
import { DepositLoodWallet } from './deposit-lood-wallet';

export const Deposit = ({ mainWallet, allWalletsBalance, user }) => {
    const [walletMode, setWalletMode] = useState('bb');
    const [showDepositModal, setShowDepositModal] = useState(false);
    const [currentBalances, setCurrentBalances] = useState([]);

    /*     useEffect(() => {
            if (coinSelectPopupVisible) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
    
            return () => {
                document.body.classList.remove('modal-open');
            };
        }, [coinSelectPopupVisible]); */

    const fetchBalances = async () => {
        const result = await InfoApiService.GetSpinBalances();
        if (result) {
            setCurrentBalances(result);
        }

    };

    useEffect(() => {
        fetchBalances();
    }, []);

    return (
        <>
            <div className='badges mt12 wallet-mode-badges'>
                <div
                    onClick={() => setWalletMode('bb')}
                    className={`wallet-mode-badge ${walletMode === 'bb' ? 'wallet-mode-badge-active' : ''}`}
                >
                    BuyBag
                </div>
                <div
                    onClick={() => setWalletMode('turbowallet')}
                    className={`wallet-mode-badge ${walletMode === 'turbowallet' ? 'wallet-mode-badge-active' : ''}`}
                >
                    Turbo Wallet
                </div>
            </div>
            {walletMode === 'bb' &&
                <>
                    <TonConnectCrisper mainWallet={mainWallet}></TonConnectCrisper>
                </>
            }
            {walletMode === "turbowallet" &&
                <>
                    <div className='asset_title' style={{ padding: "12px 12px 0px 12px" }}>Fast wallet for spins, fires, and sending coins in chats</div>

                    <div className={'default_next_btn btn_red'} onClick={() => setShowDepositModal(true)}>
                        Deposit Turbo Wallet
                    </div>
                </>
            }
            <DepositLoodWallet minHeight={550} shown={showDepositModal} allWalletsBalance={allWalletsBalance} whiteList={currentBalances.map(x => x.coinAddress)} tonBalance={user?.balance} userWallet={mainWallet} close={() => {
                setShowDepositModal(false);
            }} />
        </>
    );
}
