import React, { useState, useEffect } from 'react';
import loadingAD from '../lotties/loading.json';
import { formattedNumber } from './formattedNumber';
import { ProfileApiService } from '../services/profile.api.service';
import { useSWRConfig } from "swr"
import { formattedNumberShort } from './formattedNumberShort';

export const TokenList = ({ user, allWalletsBalance }) => {
    const [inited, setInited] = useState(false);
    /* const [allWalletsBalance, setAllWalletsBalance] = useState([]); */

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    /*     const { mutate } = useSWRConfig()
        const profileHookUrl = ProfileApiService.GetProfileHookUrl();
    
        const fetchWalletsBalance = async () => {
            try {
                const result = await ProfileApiService.GetAllWalletsBalanceInfo();
                let balances = result.allWalletsBalance ?? [];
                setAllWalletsBalance(balances);
         
                mutate(profileHookUrl, (currentData) => {
                    return {
                      ...currentData, 
                      allWalletsBalance: [...balances],
                      tonUsdRate: result.tonRateUsd
                    }
                  }, { revalidate: false });
    
            } catch (error) {
                console.error("Ошибка при получении баланса кошельков:", error);
            }
        };
    
        useEffect(() => {
            // Первоначальный вызов для получения данных
            fetchWalletsBalance();
    
            // Устанавливаем интервал для обновления данных каждые 15 секунд
            const intervalId = setInterval(fetchWalletsBalance, 30000);
    
            // Очистка интервала при размонтировании компонента
            return () => clearInterval(intervalId);
        }, []); // Пустой массив зависимостей */

    return (
        <>
            {allWalletsBalance && allWalletsBalance.length > 0 &&
                <div className="settings_coin_balances">
                    <div className='plain_rounded_list_header'>My Bags</div>
                    {allWalletsBalance.filter(coin => coin.balance > 0.0009).sort((a, b) => b.balance - a.balance).map((coin) =>
                        <div className='asset_item_plain asset_item_dark' key={coin.address}>
                            <img className='asset_item_image' src={coin?.image} />
                            <div className='asset_desc'>
                                <div className='asset_short_title'>{coin?.shortTitle}</div>
                                <div className='asset_title'>{coin.rateUsd ? "$" + formattedNumber(coin.rateUsd) : coin?.title}</div>
                            </div>
                            <div className='asset_balance_desc'>
                                <div className='asset_balance_wrapper'>
                                    {(typeof coin?.turboWalletBalance !== 'undefined' && 
                                        coin?.turboWalletBalance !== null && 
                                        Number(coin?.turboWalletBalance) > 0) && (
                                            <div className='turbo_balance'>
                                                <div className='turbo_badge'>
                                                    <svg className="turbo_icon" width="12" height="12" viewBox="0 0 24 24" fill="none">
                                                        <path d="M13 10V3L4 14H11V21L20 10H13Z" stroke="yellow" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    {formattedNumberShort(coin.turboWalletBalance)}
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className='asset_balance'>{formattedNumber(coin?.balance ?? 0)}</div>

                                </div>
                                {coin?.rateUsd && coin?.rateUsd > 0 &&
                                    <div className='asset_usd_balance'>
                                        ${formattedNumber(coin?.balance * coin?.rateUsd)}
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </div>
            }
        </>
    );
}
