import React, {useEffect} from 'react';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import useSWR from 'swr';
import { InfoApiService } from '../services/info.api.service';

export function SwapHistory({ shown, close }) {

    useEffect(() => {
        if (shown) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [shown]);

    const swapHistoryHookUrl = InfoApiService.GetSwapHistoryHookUrl();
    
    const swapHistoryFetcher = async (url) => {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const response = await fetch(url, {headers});
        if (response.status !== 200) {
            return [];
        }
        return response.json();
    };

    const {
        data: swapHistoryData,
        isLoading: isSwapHistoryLoading,
        error: swapHistoryError
    } = useSWR(swapHistoryHookUrl, swapHistoryFetcher, {
        refreshInterval: 12000,
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true
    });


    if (!shown) {
        return null;
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={'modal_container visible'}>
            <div onClick={close} className='modal_overlay_half'></div>
            <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: "80vh"}}>
                <div className='bottom_stuck_modal_content pdside16'>
                    <div className='mtxl'></div>
                    <div className='popup_title'>
                        Swap History
                    </div>
                    <div className="swap-history-list mtl">
                        {isSwapHistoryLoading && (
                            <div className="swap-history-loading">
                                <Lottie
                                    options={loadingOptions}
                                    height={40}
                                    width={40}
                                    speed={1.5}
                                />
                            </div>
                        )}
                        {swapHistoryData && swapHistoryData.length === 0 && (
                            <div className="regularText">No swaps yet</div>
                        )}
                        {swapHistoryData && swapHistoryData.map((swap, index) => (
                            <SwapHistoryItem key={index} swap={swap} loadingOptions={loadingOptions} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function SwapHistoryItem({ swap, loadingOptions }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        
        // Проверяем сегодняшнюю дату
        if (date.toDateString() === now.toDateString()) {
            return date.toLocaleTimeString('en-US', { 
                hour: 'numeric', 
                minute: '2-digit',
                hour12: true 
            });
        }
        
        // Проверяем вчерашнюю дату
        if (date.toDateString() === yesterday.toDateString()) {
            return `Yesterday, ${date.toLocaleTimeString('en-US', { 
                hour: 'numeric', 
                minute: '2-digit',
                hour12: true 
            })}`;
        }
        
        // Для более старых дат
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    return (
        <div className="swap-history-item">
            <div className={`swap-history-item__status ${
                swap?.status === 1 ? 'success' :
                swap?.status === 2 ? 'failed' : 'loading'
            }`}>
                {swap?.status === 0 ? (
                    <div className="swap-history-item__loading">
                        <Lottie
                            options={loadingOptions}
                            speed={1.5}
                        />
                    </div>
                ) : swap?.status === 1 ? (
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6L9 17L4 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                ) : (
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                )}
            </div>
            <div className="swap-history-item__left">
                <div className="swap-history-item__icon-wrapper">
                    <div className="swap-history-item__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                            <path d="M16.0503 12.0498L21 16.9996L16.0503 21.9493L14.636 20.5351L17.172 17.9988L4 17.9996V15.9996L17.172 15.9988L14.636 13.464L16.0503 12.0498ZM7.94975 2.0498L9.36396 3.46402L6.828 5.9988L20 5.99955V7.99955L6.828 7.9988L9.36396 10.5351L7.94975 11.9493L3 6.99955L7.94975 2.0498Z"></path>
                        </svg>
                    </div>
                    <div className="swap-history-item__source-badge">
                        <img 
                            src={swap?.source === 100 ? "/images/dedusticon.png" : "/images/stonfiicon.png"} 
                            alt={swap?.source === 100 ? "Dedust" : "Stonfi"} 
                        />
                    </div>
                </div>
                <div className="swap-history-item__info">
                    <div className="swap-history-item__title">Swap</div>
                    <div className="swap-history-item__address">
                        {swap?.ownerAddress?.slice(0, 4)}...{swap?.ownerAddress?.slice(-4)}
                    </div>
                </div>
            </div>
            <div className="swap-history-item__right">
                <div className="swap-history-item__amounts">
                    {swap?.totalAmount ? (
                        <div className="swap-history-item__amount swap-history-item__amount--positive">
                            +{swap.totalAmount} {swap?.toTitle}
                        </div>
                    ) : (
                        <div className="swap-history-item__amount swap-history-item__amount--pending">
                            {swap?.status === 0 ? `Waiting for ${swap?.toTitle}` : `Swap to ${swap?.toTitle}`}
                        </div>
                    )}
                    <div className="swap-history-item__amount swap-history-item__amount--negative">
                        -{swap?.amountIn} {swap?.fromTitle}
                    </div>
                </div>
                <div className="swap-history-item__time">
                    {swap?.created && formatDate(swap.created)}
                </div>
            </div>
        </div>
    );
}