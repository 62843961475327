import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Onboarding } from "../components/onboarding";
import { MainApiService } from "../services/main.api.service";
import React, { useState, useEffect, useRef } from 'react';
import { StaticDataHelper } from "../helpers/static-data-helper";
import { useTonAddress } from '@tonconnect/ui-react';
import { AlertHistory } from "../components/alert-history";

const Layout = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const userFriendlyAddress = useTonAddress();
    const [showAlertHistoryModal, setShowAlertHistoryModal] = useState(false);
    const [hasUnreadAlerts, setHasUnreadAlerts] = useState(false);



    const tonConnectRef = useRef();

    const tonConnect = () => {
        if (tonConnectRef.current) {
            tonConnectRef.current(); // Вызов функции из main.js
        }
    };

    const handleUnreadAlertsChange = (hasUnread) => {
        console.log(hasUnread);
        setHasUnreadAlerts(hasUnread);
    };

    const initData = window?.Telegram?.WebApp?.initData;

    useEffect(() => {
        if (initData && initData !== '' && window.location.search) {
            const query = window.location.search;

            switch (true) {
                case query.includes("pgbotsetup"):
                    navigate("/tg-bot-setup");
                    break;
                case query.includes("pgmnsettings"):
                    navigate("/profile");
                    break;
                case query.includes("pgjtpg"):
                    const parameters = query.split(StaticDataHelper.StartParamSeparator());
                    const caParam = parameters.find(param => param.startsWith('jtca'));
                    if (caParam) {
                        const coinAddress = caParam.replace('jtca', '');

                        navigate("/jetton/" + coinAddress);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [navigate]);

    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        if (!initData || initData === '') {
            return <>
                <div style={{ marginTop: "50vh", textAlign: "center" }}>
                    <div className="h4">Telegram only Mini App</div>
                    <div className="h4">Site here</div>
                    <a href="https://wendizel.com" className="h4">https://wendizel.com</a>
                </div>
            </>;
        }
    }


    window.Telegram.WebApp.expand();

    var BackButton = window.Telegram.WebApp.BackButton;
    BackButton.onClick(function () {
        BackButton.hide();
    });

    window.Telegram.WebApp.onEvent('backButtonClicked', function () {
        navigate("/");
    });



    return (
        <>
            <Onboarding></Onboarding>
            <div className="header">
                <div className="header_left">
                    <div className="header_logo"><img width={24} src="/images/money_with_wings.png" alt="logo" /></div>
                    <div className="header_title">BuyBag<span className="header_title_second">Beta</span></div>
                </div>
                <div className="header_right">
                    {location.pathname === "/" && !userFriendlyAddress && <div onClick={tonConnect} className="header_airdrop blue_style">Connect Wallet</div>}
                    <div
                        onClick={() => {
                            setShowAlertHistoryModal(true);
                        }}
                        className={`header_icon ${hasUnreadAlerts === true ? 'alert-highlight' : ''}`}
                    >

                        <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                        </svg>
                    </div>
                    <Link to="/profile"><div className="header_settings header_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>

                    </div></Link>
                    {/* {location.pathname === "/" && <Link to="/profile"><div className="header_settings"><img src="/images/settings.png" alt="settings" /></div></Link>}
                    {location.pathname === "/profile" && <Link to="/"><div className="header_back"><img className="caret_left" src="/images/caret.png" alt="settings" />&nbsp;Back</div></Link>} */}
                </div>
            </div>
            <AlertHistory
                onUnreadAlertsChange={handleUnreadAlertsChange}
                shown={showAlertHistoryModal}
                close={() => {
                    setShowAlertHistoryModal(false);
                }}
            />
            <Outlet context={{ tonConnectRef }} />
        </>
    )
};

export default Layout;