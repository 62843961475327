import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ActionApiService } from '../services/action.api.service';
import Lottie from 'react-lottie';
import rocketAD from '../lotties/rocket.json';
import { toast, Bounce } from 'react-toastify';
import loadingAD from '../lotties/loading.json';
import { TonCenterApiService } from '../services/toncenter.api.service';
import TonWeb from "tonweb";
import { TonHelper } from '../helpers/ton-helper';
import { SwapSettings } from './swap-settings';
import FormattedNumberInput from './formattedNumberInput';
import { formattedNumber } from './formattedNumber';
import { InfoApiService } from '../services/info.api.service';
import { SwapHistory } from './swap-history';

export const Swap = (props) => {
    const [activeFirstCoinType, setActiveFirstCoinType] = useState({ shortTitle: 'TON', title: 'Toncoin', image: 'https://assets.dedust.io/images/ton.webp' });
    const [activeSecondCoinType, setActiveSecondCoinType] = useState(TonHelper.WendAsset());
    const [exchangeAmount, setExchangeAmount] = useState("0");
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const [getSwapInfoInProgress, setGetSwapInfoInProgress] = useState(false);
    const [swapInfo, setSwapInfo] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [coinsToDisplay, setCoinsToDisplay] = useState([]);
    const [allCoins, setAllCoins] = useState([]);
    const [totalCoins, setTotalCoins] = useState(0);
    const [coinPopupVisible, setCoinPopupVisible] = useState(false);
    const [coinSelectionType, setCoinSelectionType] = useState('from');
    const [coinSearchQuery, setCoinSearchQuery] = useState('');
    const [showSwapSettingsModal, setShowSwapSettingsModal] = useState(false);
    const [showSwapHistoryModal, setShowSwapHistoryModal] = useState(false);
    const [firstCoinBalance, setFirstCoinBalance] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [historyHighlight, setHistoryHighlight] = useState(false);
    let allCoinsBalances = props.allCoinsBalances;

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (exchangeAmount === '0' || exchangeAmount === 0 || exchangeAmount === '') {
                resetForm();
                return;
            }
            updateSwapInfo();
        }, 800);
        return () => clearTimeout(timeOutId);
    }, [exchangeAmount, activeFirstCoinType, activeSecondCoinType]);

    useEffect(() => {
        if (activeFirstCoinType?.shortTitle === 'TON') {
            setFirstCoinBalance(Number(props?.tonBalance.toString().replace(/,/g, '.')));
        }
        else {
            let balance = allCoinsBalances.find(x => x?.title == activeFirstCoinType?.title)?.balance ?? "0,00";
            setFirstCoinBalance(Number(balance.toString().replace(/,/g, '.')));
        }
    }, [activeFirstCoinType]);


    function updateSwapInfo() {
        setControlsDisabled(true);
        setGetSwapInfoInProgress(true);
        ActionApiService.GetSwapInfo(exchangeAmount, activeFirstCoinType.shortTitle === 'TON' ? activeSecondCoinType.address : activeFirstCoinType.address, activeFirstCoinType.shortTitle === 'TON' ? 100 : 200).then(result => {
            setSwapInfo(result);
            setControlsDisabled(false);
        }).catch((error) => {
            resetForm();
            setControlsDisabled(false);
        }).finally(() => {
            setGetSwapInfoInProgress(false)
        });
    }
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (!coinSearchQuery || coinSearchQuery === null || coinSearchQuery === undefined || coinSearchQuery === '') {
                // Если поиск пустой - возвращаемся к начальному состоянию
                setPage(1);
                loadInitialCoins();
                return;
            }

            // Функция для выполнения поиска
            const performSearch = async () => {
                setIsLoading(true);
                try {
                    const response = await InfoApiService.Coins(coinSearchQuery, 1, 50);

                    // Если нет результатов и длина запроса > 30 (возможно это адрес)
                    if (response.items.length === 0 && coinSearchQuery.length > 30) {
                        try {
                            let addressSearchQuery = coinSearchQuery;
                            try {
                                let bounceAddress = TonHelper.GetBounceableAddress(coinSearchQuery);
                                addressSearchQuery = bounceAddress;
                            } catch { }

                            const jettonContent = await TonCenterApiService.GetJettonInfo(addressSearchQuery);

                            if (jettonContent !== null) {
                                if (!allCoins.some(x => x.title == jettonContent?.name)) {
                                    try {
                                        let bounceAddress = TonHelper.GetBounceableAddress(jettonContent?.address);

                                        let coinAsset = {
                                            shortTitle: jettonContent?.symbol,
                                            title: jettonContent?.name,
                                            image: jettonContent?.image ?? TonHelper.NoImageAsset(),
                                            address: bounceAddress,
                                            isImported: true
                                        };

                                        setCoinsToDisplay([coinAsset]); // Показываем только импортированную монету
                                        setAllCoins(prev => [...prev, coinAsset]);
                                        setHasMore(false); // Отключаем подгрузку при импорте
                                        setTotalCoins(1); // Устанавливаем общее количество в 1
                                    } catch { }
                                }
                            }
                        } catch (error) {
                            console.error('Failed to import coin:', error);
                            setCoinsToDisplay([]);
                            setHasMore(false);
                            setTotalCoins(0);
                        }
                    } else {
                        setCoinsToDisplay(response.items);
                        setAllCoins(prev => [...prev, ...response.items]);
                        setHasMore(response.hasMore);
                        setPage(1);
                        setTotalCoins(response.total);
                    }
                } catch (error) {
                    console.error('Failed to search coins:', error);
                    setCoinsToDisplay([]);
                    setHasMore(false);
                    setTotalCoins(0);
                } finally {
                    setIsLoading(false);
                }
            };

            performSearch();
        }, 800);

        return () => clearTimeout(timeOutId);
    }, [coinSearchQuery]);

    const loadInitialCoins = async () => {
        setIsLoading(true);
        try {
            const response = await InfoApiService.Coins('', 1, 50);
            setCoinsToDisplay(response.items);
            setAllCoins(response.items);
            setTotalCoins(response.total);
            setHasMore(response.hasMore);
        } catch (error) {
            console.error('Failed to load coins:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadInitialCoins();
    }, []);


    const loadMoreCoins = async () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);
        try {
            const nextPage = page + 1;
            const response = await InfoApiService.Coins('', nextPage, 50);

            setCoinsToDisplay(prev => [...prev, ...response.items]);
            setAllCoins(prev => [...prev, ...response.items]);
            setHasMore(response.hasMore);
            setPage(nextPage);
        } catch (error) {
            console.error('Failed to load more coins:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const observer = useRef();
    const lastCoinElementRef = useCallback(node => {
        if (isLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                loadMoreCoins();
            }
        });

        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);


    function swapCoinTypes() {
        let currentFirst = { ...activeFirstCoinType };
        let currentSecond = { ...activeSecondCoinType };
        setActiveFirstCoinType(currentSecond);
        setActiveSecondCoinType(currentFirst);
    }

    function startChooseCoin(type) {
        setCoinSelectionType(type);
        setCoinsToDisplay(allCoins);
        setCoinSearchQuery('');
        setCoinPopupVisible(true);
    }

    function resetForm() {
        let newSwapInfo = {
            fromAmountInUsd: 0,
            toAmount: 0,
            toAmountInUsd: 0
        };

        setSwapInfo(newSwapInfo);
    }

    function setMaxExchangeAmount() {
        let resultToSet = 0;
        if (activeFirstCoinType.shortTitle === 'TON') {
            resultToSet = firstCoinBalance - 0.5;
            if (resultToSet < 0)
                resultToSet = firstCoinBalance;
        }
        else {
            resultToSet = firstCoinBalance
        }

        setExchangeAmount(resultToSet.toFixed(3));
    }

    function calculatePercentageDifference(fromAmount, toAmount) {
        const difference = ((toAmount - fromAmount) / fromAmount) * 100;

        return Math.abs(difference).toFixed(2);
    }

    function swap() {
        if (exchangeAmount > 0 && controlsDisabled === false && swapInfo?.toAmount > 0) {
            setControlsDisabled(true);
            ActionApiService.Swap(exchangeAmount, activeFirstCoinType.shortTitle === 'TON' ? activeSecondCoinType.address : activeFirstCoinType.address, activeFirstCoinType.shortTitle === 'TON' ? 100 : 200, swapInfo?.toAmount, swapInfo?.routerAddress).then(result => {
                if (result.isSuccess === true) {
                    // setExchangeAmount(0);
                    // resetForm();
                    setHistoryHighlight(true);
                    toast.success('Sent!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                }
                else {
                    toast.error(result.message ?? 'Failed.', {
                        position: "top-right",
                        autoClose: result.message?.startsWith("Min Balance:") ? 4000 : 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "dark",
                        transition: Bounce,
                    });
                }

                setControlsDisabled(false);
            }).catch((error) => {
                setControlsDisabled(false);
            });
        }
    }

    function selectCoin(coin) {
        if (coinSelectionType === 'from') {
            setActiveFirstCoinType(coin);
            if (coin.shortTitle !== "TON" && activeSecondCoinType.shortTitle !== "TON") {
                let nativeCoin = { shortTitle: 'TON', image: 'https://assets.dedust.io/images/ton.webp' }
                setActiveSecondCoinType(nativeCoin);
            }
        }
        else {
            setActiveSecondCoinType(coin);
            if (coin.shortTitle !== "TON" && activeFirstCoinType.shortTitle !== "TON") {
                let nativeCoin = { shortTitle: 'TON', image: 'https://assets.dedust.io/images/ton.webp' }
                setActiveFirstCoinType(nativeCoin)
            }
        }

        setCoinPopupVisible(false);
    }


    function toPlainString(num) {
        return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
            function (a, b, c, d, e) {
                return e < 0
                    ? b + '0,' + Array(1 - e - c.length).join(0) + c + d
                    : b + c + d + Array(e - d.length + 1).join(0);
            });
    }

    const rocketOptions = {
        loop: true,
        autoplay: true,
        animationData: rocketAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };



    return (
        <>
            <div className={(controlsDisabled === true ? 'menu_content pl unclickable' : 'menu_content pl')}>
                {controlsDisabled &&
                    <div className="loading_crisper_wrapper">
                        <div className="loading_crisper">
                            <Lottie
                                options={loadingOptions}
                                height={200}
                                width={200}
                                speed={1.5}
                            />
                        </div>
                    </div>
                }
                <div className="swap_toolbar">
                    <div className="toolbar_header">Swap</div>
                    <div className="df">
                        <div onClick={() => updateSwapInfo()} className={'swap_refresh ' + (controlsDisabled === false && exchangeAmount > 0 ? '' : 'unclickable') + (getSwapInfoInProgress === true ? ' rotate' : '')}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 30 30">
                                <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
                            </svg>
                        </div>
                        <div onClick={() => setShowSwapSettingsModal(true)} className="swap_settings">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.99961 3.09998C7.15546 3.09998 5.61027 4.37996 5.20397 6.09998H2.99961C2.50255 6.09998 2.09961 6.50292 2.09961 6.99998C2.09961 7.49703 2.50255 7.89998 2.99961 7.89998H5.20397C5.61027 9.62 7.15546 10.9 8.99961 10.9C10.8438 10.9 12.389 9.62 12.7952 7.89998H20.9996C21.4967 7.89998 21.8996 7.49703 21.8996 6.99998C21.8996 6.50292 21.4967 6.09998 20.9996 6.09998L12.7953 6.09998C12.389 4.37996 10.8438 3.09998 8.99961 3.09998ZM8.99961 9.09998C7.83981 9.09998 6.89961 8.15977 6.89961 6.99998C6.89961 5.84018 7.83981 4.89998 8.99961 4.89998C10.1594 4.89998 11.0996 5.84018 11.0996 6.99998C11.0996 8.15977 10.1594 9.09998 8.99961 9.09998Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M11.204 17.9H2.99961C2.50255 17.9 2.09961 17.497 2.09961 17C2.09961 16.5029 2.50255 16.1 2.99961 16.1H11.204C11.6103 14.38 13.1555 13.1 14.9996 13.1C16.8438 13.1 18.389 14.38 18.7952 16.1H20.9996C21.4967 16.1 21.8996 16.5029 21.8996 17C21.8996 17.497 21.4967 17.9 20.9996 17.9H18.7952C18.389 19.62 16.8438 20.9 14.9996 20.9C13.1555 20.9 11.6103 19.62 11.204 17.9ZM17.0996 17C17.0996 15.8402 16.1594 14.9 14.9996 14.9C13.8398 14.9 12.8996 15.8402 12.8996 17C12.8996 18.1598 13.8398 19.1 14.9996 19.1C16.1594 19.1 17.0996 18.1598 17.0996 17Z" fill="currentColor"></path></svg>
                        </div>
                        <div
                            onClick={() => {
                                setShowSwapHistoryModal(true);
                                setHistoryHighlight(false);
                            }}
                            className={`swap_settings ${historyHighlight ? 'history-highlight' : ''}`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                                <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12H4C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C9.25022 4 6.82447 5.38734 5.38451 7.50024L8 7.5V9.5H2V3.5H4L3.99989 5.99918C5.82434 3.57075 8.72873 2 12 2ZM13 7L12.9998 11.585L16.2426 14.8284L14.8284 16.2426L10.9998 12.413L11 7H13Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="swap_up_part">
                    <div className="swap_up_part_first_row">
                        <div className="swap_coin_type" onClick={() => startChooseCoin('from')}>
                            <img className='swap_coin_type_caret' src="/images/caret.png" width={24} />
                            <div className="swap_coin_type_title">{activeFirstCoinType?.shortTitle}</div>
                            <img className="swap_coin_type_icon" src={activeFirstCoinType?.image} width={24} height={24} />
                        </div>
                        <div className={"swap_right_up_part" + (firstCoinBalance > 0 ? " blue_text" : "")} onClick={() => setMaxExchangeAmount()}>
                            {firstCoinBalance > 0 && <img src="/images/blue_credit_card.png" width={24} height={24} />}
                            {formattedNumber(activeFirstCoinType?.shortTitle === 'TON' ? props?.tonBalance : (allCoinsBalances.find(x => x?.title == activeFirstCoinType?.title)?.balance ?? "0,00"))}
                        </div>
                    </div>
                    <div className="swap_up_part_second_row">
                        <FormattedNumberInput
                            className="swap_input"
                            value={exchangeAmount}
                            onChange={setExchangeAmount}
                        />
                        <div className="swap_right_down_part">{"≈ $" + (swapInfo?.fromAmountInUsd ?? 0)}</div>
                    </div>
                </div>
                <div className="swap_middle_part">
                    <div className="line"></div>
                    <img src="/images/swap_btn.png" className="swapCoinBtn" onClick={() => swapCoinTypes()} width={32} height={32} />
                    <div className="line"></div>
                </div>
                <div className="swap_down_part">
                    <div className="swap_up_part_first_row">
                        <div className="swap_coin_type" onClick={() => startChooseCoin('to')}>
                            <img className='swap_coin_type_caret' src="/images/caret.png" width={24} />
                            <div className="swap_coin_type_title">{activeSecondCoinType?.shortTitle}</div>
                            <img className="swap_coin_type_icon" src={activeSecondCoinType?.image} width={24} height={24} />
                        </div>
                        <div className="swap_right_up_part">{formattedNumber(activeSecondCoinType?.shortTitle === 'TON' ? props?.tonBalance : (allCoinsBalances.find(x => x?.title == activeSecondCoinType?.title)?.balance ?? "0,00"))}</div>
                    </div>
                    <div className="swap_up_part_second_row">
                        <FormattedNumberInput className="swap_input" placeholder="0" value={toPlainString(swapInfo?.toAmount ?? 0)} readOnly />
                        <div className="swap_right_down_part">
                            <div className="usd-amount">
                                {"≈ $" + (swapInfo?.toAmountInUsd != null ? swapInfo.toAmountInUsd : "0")}
                            </div>
                            {swapInfo?.fromAmountInUsd > 0 && swapInfo?.toAmountInUsd > 0 && swapInfo?.toAmountInUsd !== swapInfo?.fromAmountInUsd &&
                                <div className={`percentage-difference ${swapInfo.toAmountInUsd >= swapInfo.fromAmountInUsd ? 'positive' : 'negative'
                                    }`}>
                                    {swapInfo.toAmountInUsd >= swapInfo.fromAmountInUsd ? '(+' : '(-'}
                                    {calculatePercentageDifference(swapInfo.fromAmountInUsd, swapInfo.toAmountInUsd)}%{")"}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {swapInfo?.details?.priceImpact &&
                    <>
                        <div className="line mtd"></div>
                        <div class="swap-details">
                            <div class="swap-details-header" onClick={() => setIsExpanded(!isExpanded)}>
                                <span>Swap details</span>
                                <img src="/images/caret.png" className={isExpanded ? 'caret-expanded' : ''} />
                            </div>

                            {isExpanded && (
                                <div class="swap-details-content">
                                    <div class="swap-detail-row">
                                        <div class="swap-detail-label">1 {activeFirstCoinType?.shortTitle} price</div>
                                        <div class="swap-detail-value">
                                            ≈ {swapInfo?.details?.firstPrice} {activeSecondCoinType?.shortTitle}
                                            {/* <span class="usd-value">($5.79)</span> */}
                                        </div>
                                    </div>

                                    <div class="swap-detail-row">
                                        <div class="swap-detail-label">1 {activeSecondCoinType?.shortTitle} price</div>
                                        <div class="swap-detail-value">
                                            ≈ {swapInfo?.details?.secondPrice} {activeFirstCoinType?.shortTitle}
                                            {/* <span class="usd-value">($4.45)</span> */}
                                        </div>
                                    </div>

                                    <div class="swap-detail-row">
                                        <div class="swap-detail-label">
                                            Max. slippage
                                            {/* <img src="/images/info-icon.png" class="info-icon" /> */}
                                        </div>
                                        <div class="swap-detail-value">{swapInfo?.details?.slippage}%</div>
                                    </div>

                                    <div class="swap-detail-row">
                                        <div class="swap-detail-label">
                                            Minimum received
                                            {/* <img src="/images/info-icon.png" class="info-icon" /> */}
                                        </div>
                                        <div class="swap-detail-value">{swapInfo?.details?.minAmount} {activeSecondCoinType?.shortTitle}</div>
                                    </div>

                                    <div class="swap-detail-row">
                                        <div class="swap-detail-label">
                                            Price impact
                                            {/* <img src="/images/info-icon.png" class="info-icon" /> */}
                                        </div>
                                        <div class="swap-detail-value">{swapInfo?.details?.priceImpact}%</div>
                                    </div>

                                    <div class="swap-detail-row">
                                        <div class="swap-detail-label">Blockchain fee</div>
                                        <div class="swap-detail-value">0.05-0.3 TON</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                }

                <div onClick={() => swap()} className='swap_btn'>
                    <div className='swap_btn_icon'>
                        <Lottie
                            options={rocketOptions}
                            height={20}
                            width={20} />
                    </div>
                    <div className='swap_btn_text'>Swap</div>
                </div>
            </div>
            {coinPopupVisible &&
                <div className='bottom_stuck_modal'>
                    <div className="bottom_stuck_modal_content">
                        <div className='close_btn' onClick={() => setCoinPopupVisible(false)}><img src='/images/caret.png' /></div>
                        <div className='popup_title'>
                            Choose the Asset
                        </div>
                        <div className='popup_text'>{coinSelectionType === 'from' ? 'you will pay with' : 'you want to receive'}</div>
                        <input className='asset_search_input' type="text" placeholder='Search for coin' value={coinSearchQuery} onChange={(event) => setCoinSearchQuery(event.target.value)} />

                        {(!coinsToDisplay || coinsToDisplay.length === 0) && !isLoading && (
                            <div className='regularText not_found_message'>Not Found :&#40;</div>
                        )}
                        {coinsToDisplay && coinsToDisplay.length > 0 &&
                            <>
                                <div className='assets_crisper'>
                                    {coinsToDisplay.map((coin, index) =>
                                        <div
                                            className='asset_item'
                                            key={index}
                                            ref={index === coinsToDisplay.length - 1 ? lastCoinElementRef : null}
                                            onClick={() => { selectCoin(coin); }}
                                        >
                                            <img className='asset_item_image' src={coin?.image} />
                                            <div className='asset_desc'>
                                                <div className='asset_short_title'>
                                                    {coin?.shortTitle}
                                                    {coin.isImported === true &&
                                                        <span className='imported_badge'>Imported</span>
                                                    }
                                                </div>
                                                <div className='asset_title'>{coin?.title}</div>
                                            </div>
                                            <div className='asset_balance_desc'>
                                                <div className='asset_balance'>
                                                    {formattedNumber(allCoinsBalances.find(x => x?.title == coin.title)?.balance ?? 0)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {isLoading &&
                                        <div className="loading-indicator">Loading...</div>
                                    }
                                </div>
                            </>
                        }

                    </div>

                </div>
            }

            <SwapSettings shown={showSwapSettingsModal} userSlippage={props?.userSlippage} userMevProtection={props?.userMevProtection} close={() => {
                setShowSwapSettingsModal(false);
            }} />

            <SwapHistory
                shown={showSwapHistoryModal}
                close={() => {
                    setShowSwapHistoryModal(false);
                    setHistoryHighlight(false);
                }}
            />
        </>
    );
}
