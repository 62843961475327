import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import useSWR from 'swr';
import { InfoApiService } from '../services/info.api.service';

export function AlertHistory({ shown, close, onUnreadAlertsChange }) {

    useEffect(() => {
        if (shown) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [shown]);

    const alertsHistoryHookUrl = InfoApiService.GetAlertsHistoryHookUrl();

    const alertsHistoryFetcher = async (url) => {
        const accessToken = localStorage.getItem('bb_token');
        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const response = await fetch(url, { headers });
        if (response.status !== 200) {
            return [];
        }
        return response.json();
    };

    const {
        data: alertsHistoryData,
        isLoading: isAlertsHistoryLoading,
        error: alertsHistoryError,
        mutate
    } = useSWR(alertsHistoryHookUrl, alertsHistoryFetcher, {
        refreshInterval: 12000,
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: true
    });


    useEffect(() => {
        if (alertsHistoryData) {
            const hasUnreadAlerts = alertsHistoryData.some(alert => !alert.isRead);
            console.log('hasUnreadAlerts', hasUnreadAlerts);
            onUnreadAlertsChange?.(hasUnreadAlerts);
        }
    }, [alertsHistoryData, onUnreadAlertsChange]);

    const prevShown = usePrevious(shown);
    useEffect(() => {
        if (prevShown && !shown && alertsHistoryData?.some(alert => !alert.isRead)) {
            InfoApiService.MarkAllAlertsAsRead()
                .then(() => {
                    mutate(
                        alertsHistoryData.map(alert => ({
                            ...alert,
                            isRead: true
                        })),
                        false
                    );
                })
                .catch(error => console.error('Failed to mark alerts as read:', error));
        }
    }, [shown]);


    if (!shown) {
        return null;
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={'modal_container visible'}>
            <div onClick={close} className='modal_overlay_half'></div>
            <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: "80vh" }}>
                <div className='bottom_stuck_modal_content pdside16'>
                    <div className='mtxl'></div>
                    <div className='popup_title'>
                        Alerts
                    </div>
                    <div className="swap-history-list mtl">
                        {isAlertsHistoryLoading && (
                            <div className="swap-history-loading">
                                <Lottie
                                    options={loadingOptions}
                                    height={40}
                                    width={40}
                                    speed={1.5}
                                />
                            </div>
                        )}
                        {alertsHistoryData && alertsHistoryData.length === 0 && (
                            <div className="regularText">No alerts yet</div>
                        )}
                        {alertsHistoryData && alertsHistoryData.map((alertItem, index) => (
                            <AlertHistoryItem key={index} alertItem={alertItem} loadingOptions={loadingOptions} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function AlertHistoryItem({ alertItem, loadingOptions }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);

        // Проверяем сегодняшнюю дату
        if (date.toDateString() === now.toDateString()) {
            return date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
        }

        // Проверяем вчерашнюю дату
        if (date.toDateString() === yesterday.toDateString()) {
            return `Yesterday, ${date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            })}`;
        }

        // Для более старых дат
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    };

    return (
        <div className="swap-history-item">
            <div className={`swap-history-item__status success`}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 6L9 17L4 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="swap-history-item__left">
                <div className="swap-history-item__icon-wrapper">
                    <div className="swap-history-item__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} stroke-width="1.5" fill="#E6B800" stroke="#CD9B1D   " class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                        </svg>
                    </div>
                </div>
                <div className="swap-history-item__info">
                    <div className="swap-history-item__title">Received</div>
                    <div className="swap-history-item__address">
                        From {alertItem?.receivedFrom}
                    </div>
                </div>
            </div>
            <div className="swap-history-item__right">
                <div className="swap-history-item__amounts">
                    {alertItem?.receivedAmount && (
                        <div className="swap-history-item__amount swap-history-item__amount--positive">
                            +{alertItem.receivedAmount} {alertItem?.assetTitle}
                        </div>
                    )}
                </div>
                <div className="swap-history-item__time">
                    {alertItem?.created && formatDate(alertItem.created)}
                </div>
            </div>
        </div>
    );
}


function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}